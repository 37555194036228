<template>
  <div class="page">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/sell-during" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent>
            <p class="promo promo_blocks">
              {{ "s2p9_text_1" | localize }}<br />{{ "s2p9_text_2" | localize }}
            </p>
            <v-radio-group
              v-model="month"
              class="row"
              :class="{ invalid: $v.month.$dirty && !$v.month.required }"
              ref="month"
              row
              @change="
                $v.month.$touch();
                checkForm(false);
              "
            >
              <v-radio
                :label="'s2p9_radio_1' | localize"
                value="1"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p9_radio_2' | localize"
                value="2"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
              <v-radio
                :label="'s2p9_radio_3' | localize"
                value="3"
                on-icon="icon-radio-on"
                off-icon="icon-radio-off"
              ></v-radio>
            </v-radio-group>

            <div v-if="month == 1">
              <p class="question">{{ "s2p9_text_3" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.price)"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpPriceHandler('price', price)"
                  :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                  :hint="
                    ($v.price.$dirty && !$v.price.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'c_s1p1_placeholder' | localize"
                  append-icon="icon-₪"
                  ref="price"
                  outlined
                ></v-text-field>
              </div>
            </div>

            <div v-if="month == 2">
              <p class="question">{{ "s2p9_text_4" | localize }}</p>
              <v-radio-group
                v-model="intime"
                class="row"
                :class="{ invalid: $v.intime.$dirty && !$v.intime.required }"
                ref="intime"
                row
                @change="
                  $v.intime.$touch();
                  checkForm(false);
                "
              >
                <v-radio
                  :label="'s2p9_radio_4' | localize"
                  value="1"
                  on-icon="icon-radio-on"
                  off-icon="icon-radio-off"
                ></v-radio>
                <v-radio
                  :label="'s2p9_radio_5' | localize"
                  value="2"
                  on-icon="icon-radio-on"
                  off-icon="icon-radio-off"
                ></v-radio>
              </v-radio-group>
            </div>

            <div v-if="month == 3">
              <p class="question">{{ "s2p9_text_5" | localize }}</p>
              <div class="form__group">
                <v-text-field
                  v-model="price"
                  class="input_number"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  @input="checkForm($v.price)"
                  @keypress="onKeyPressHandler"
                  @keyup="onKeyUpPriceHandler('price', price)"
                  :class="{ invalid: $v.price.$dirty && !$v.price.required }"
                  :hint="
                    ($v.price.$dirty && !$v.price.required
                      ? 'form_reqiered'
                      : 'empty') | localize
                  "
                  :placeholder="'c_s1p1_placeholder' | localize"
                  append-icon="icon-₪"
                  ref="price"
                  outlined
                ></v-text-field>
              </div>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

const mortgage = VueCookies.get('mortgage') || {}

export default {
  name: 'Mortgage-help',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    month: mortgage.month || null,
    intime: null,
    price: null
  }),
  validations: {
    month: { required },
    intime: { required },
    price: { required }
  },
  methods: {
    submitHandler () {
      this.price = this.month === '2' ? 0 : this.parseNumber(this.price)
      this.intime = this.month === '2' ? this.intime : 0

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      mortgage.month = this.month
      mortgage.intime = this.intime
      mortgage.old_price_not_sold = this.month === '2' ? 0 : this.price
      this.$cookies.set('mortgage', mortgage)

      // Сброс следующих шагов линии действий
      this.dropSteps('mortgage', this.$router.currentRoute.path, '/mortgage/old-realty-price-not-sold')

      this.addMissedSteps('mortgage', ['/mortgage/old-realty-price-not-sold'])
      this.$router.push('/mortgage/old-mortgage-not-sold-realty')
    }
  }
}
</script>
